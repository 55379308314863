<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">PROGRAM </span> LIST
    </PageHeader>

    <v-overlay :absolute="absolute" :value="overlay" z-index="90">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col>
        <v-btn
          class="ma-2 secondary--text font-weight-bold"
          to="/program_management/newprogram"
          color="primary"
        >
          CREATE NEW PROGRAM
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <Program />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="basketWithIndex"
          class="elevation-2"
          item-key="id"
          disable-pagination
          :loading="isLoaded"
          hide-default-footer
        >
          <!-- <template #item.min_credit_hours="min_credit_hours">
            <v-edit-dialog
              large
              @save="saveGradesChangesBtn(min_credit_hours.item)"
              @open="creditHours = min_credit_hours.item.min_credit_hours"
            >
              {{ min_credit_hours.item.min_credit_hours }}
              <template #input>
                <v-text-field
                  class="pt-3"
                  outlined
                  full-width
                  label="Program Name"
                  placeholder="Program Name"
                  v-model.number="creditHours"
                  hide-details="auto"
                  :rules="[(v) => !!v || 'Program is required']"
                >
                </v-text-field>
              </template>
            </v-edit-dialog>
          </template>
          <template #item.program_name="program_name">
            <v-edit-dialog
              large
              @save="saveGradesChangesBtn(program_name.item)"
              @open="programName = program_name.item.program_name"
            >
              {{ program_name.item.program_name }}
              <template #input>
                <v-text-field
                  class="pt-3"
                  outlined
                  full-width
                  label="Program Name"
                  placeholder="Program Name"
                  v-model="programName"
                  hide-details="auto"
                  :rules="[(v) => !!v || 'Program is required']"
                >
                </v-text-field>
              </template>
            </v-edit-dialog>
          </template> -->

          <!-- <template #item.duration="duration">
            <v-edit-dialog
              large
              @save="saveGradesChangesBtn(duration.item)"
              @open="durationYear = duration.item.duration"
            >
              {{ duration.item.duration }}
              <template #input>
                <v-text-field
                  class="pt-3"
                  outlined
                  full-width
                  label="Duration"
                  placeholder="Duration"
                  v-model.number="durationYear"
                  hide-details="auto"
                  :rules="[(v) => !!v || 'Duration is required']"
                >
                </v-text-field>
              </template>
            </v-edit-dialog>
          </template>
         

          <template #item.grades="grades">
            <v-edit-dialog
              large
              @save="saveGradesChangesBtn(grades.item)"
              @open="openGradesProgram(grades.item)"
            >
              Grade Type(s)
              <template #input>
                <v-select
                  chips
                  deletable-chips
                  :menu-props="{ top: true, offsetY: true }"
                  class="pt-3"
                  outlined
                  full-width
                  multiple
                  :items="getters_grades"
                  item-text="name"
                  item-value="id"
                  label="Grade"
                  placeholder="Grade(s)"
                  v-model="basket"
                  :rules="[(v) => !!v || 'Grade is required']"
                >
                  <template #prepend-item>
                    <v-list-item ripple @click="toggle">
                      <v-list-item-action>
                        <v-icon
                          :color="basket.length > 0 ? 'indigo darken-4' : ''"
                        >
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Select All </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template #append> </template>
                </v-select>
              </template>
            </v-edit-dialog>
          </template> -->

          <template #item.program_state="program_state">
            {{ program_state.item.program_state ? "Top-Up" : "No" }}
          </template>

          <template #item.grades="grades">
            <div v-for="(grade, i) in grades.item.grades" :key="i" class="my-1">
              <v-menu
                offset-y
                open-on-hover
                right
                transition="slide-x-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on="on" v-bind="attrs" depressed>
                    {{ `${grade.name} ${grade.class}% ${grade.exams}%` }}
                  </v-btn>
                </template>
                <v-list width="450">
                  <v-list-item class="font-weight-bold">
                    <v-list-item-title>From</v-list-item-title>
                    <v-list-item-title>To</v-list-item-title>
                    <v-list-item-title>Grade</v-list-item-title>
                    <v-list-item-title>Interpretation</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <template v-for="(item, index) in grade.grades">
                    <v-divider :key="`${index}-grade`"></v-divider>
                    <v-list-item :key="`${index}-list`">
                      <v-list-item-title>{{ item.from }}</v-list-item-title>
                      <v-list-item-title>{{ item.to }}</v-list-item-title>
                      <v-list-item-title>{{ item.grade }}</v-list-item-title>
                      <v-list-item-title>{{
                        item.interpretation
                      }}</v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-edit-dialog single-line>
              <v-btn dark right large icon>
                <v-icon dark class="red--text"> mdi-close-circle </v-icon>
              </v-btn>

              <template v-slot:input>
                <v-card flat>
                  <v-card-text class="pa-3 ma-0">
                    Do You Want to Delete <b>{{ item.program_name }}</b> ?
                  </v-card-text>
                  <v-card-actions class="px-0 ma-0">
                    <v-btn
                      color="red"
                      dark
                      block
                      @click="
                        removeProgramBtn(item.id, item.program_name_certificate)
                      "
                    >
                      Delete
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-edit-dialog>
          </template>
          <template v-slot:no-data>
            <span class="subheading font-weight-bold">Not available</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <Program />
      </v-col>
    </v-row>

    <Response v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import { computed, getCurrentInstance, provide, reactive, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import PageHeader from "@/components/slots/PageHeader";
  import Response from "@/components/ActionResponse/Response";
  import Program from "@/components/Pagination/Program";

  export default {
    components: { PageHeader, Response, Program },
    setup() {
      const vm = getCurrentInstance();
      const { getPaginateProgram, removeProgram, signOut, getGrades } =
        useActions([
          "getPaginateProgram",
          "removeProgram",
          "signOut",
          "getGrades",
        ]);

      const { getters_program_paginate, getters_grades } = useGetters([
        "getters_program_paginate",
        "getters_grades",
      ]);

      getPaginateProgram()
        .then(() => {
          programTable.isLoaded = false;
        })
        .catch((e) => {
          if (e.response.status === 423) {
            actionResponse.value = true;
            msgBody.value = e.response.data.message;
            signOut().then(() => {
              vm.proxy.$router.replace({
                name: "Login",
              });
            });
          }
        });

      const programTable = reactive({
        isLoaded: true,
        headers: [
          { text: "PROGRAM", value: "program_name" },
          { text: "CERTIFICATE", value: "certificate.certificate_name" },
          { text: "DURATION(YEARS)", value: "duration", align: "center" },
          { text: "TOP-UP", value: "program_state" },
          { text: "GRADING", value: "grades" },
          {
            text: "MINIMUM CREDIT HOURS",
            value: "min_credit_hours",
            align: "center",
          },
          { text: "ACTIONS", value: "actions", sortable: false },
        ],
        msgHeader: "",
        msgBody: "",
        msgIcon: "",
        color: null,
        actionResponse: false,
        absolute: true,
        overlay: false,
        newProgramText: "",
        originalDurationText: 0,
        basket: [],
        programName: null,
        durationYear: null,
        creditHours: null,
        programState: null,
      });

      const {
        basket,
        color,
        actionResponse,
        msgHeader,
        msgBody,
        msgIcon,
        overlay,
        programName,
        durationYear,
        creditHours,
        programState,
      } = toRefs(programTable);

      const basketWithIndex = computed(() => {
        return getters_program_paginate.value.map((items /* , index */) => ({
          ...items,
          /* 	index: index + 1, */
        }));
      });

      const saveGradesChangesBtn = (item) => {
        /*  if (context.refs.programChangesForm.validate()) { */
        let newchanges = {
          id: item,
        };

        console.log(
          newchanges,
          programName.value,
          durationYear.value,
          creditHours.value,
          programState.value,
          basket.value
        );
        /* saveChangesCourse(newchanges)
                .then(() => {
                  getCourses()
                    .then(() => {
                      courseChangesForm.loading = false;
                    })
                    .catch((e) => {
                      actionResponse.value = true;
                      msgHeader.value = "Error";
                      msgIcon.value = "mdi-close-circle";
                      if (e.response.status === 423) {
                        msgBody.value = e.response.data.message;
                        signOut().then(() => {
                          vm.proxy.$router.replace({
                            name: "Login",
                          });
                        });
                      }
                    });
                })
                .catch((e) => {
                  actionResponse.value = true;
                  msgHeader.value = "Error";
                  msgIcon.value = "mdi-close-circle";
                  if (e.response.status === 423) {
                    msgBody.value = e.response.data.message;
                    signOut().then(() => {
                      vm.proxy.$router.replace({
                        name: "Login",
                      });
                    });
                  }
                });
            */
        /*   } */
      };

      getGrades().catch((e) => {
        if (e.response.status === 423) {
          msgBody.value = e.response.data.message;
          signOut().then(() => {
            vm.proxy.$router.replace({
              name: "Login",
            });
          });
        }
      });

      const openGradesProgram = (item) => {
        basket.value = [];
        getters_grades.value.forEach((grade) => {
          item.grades.forEach((itemprg) => {
            if (grade.id === itemprg.id && !basket.value.includes(grade.id)) {
              basket.value.push(grade.id);
            }
          });
        });
      };

      const selectAll = computed(() => {
        return getters_grades.value.length === basket.value.length;
      });

      const selectSome = computed(() => {
        return (
          basket.value.length > 0 &&
          basket.value.length < getters_grades.value.length
        );
      });

      const icon = computed(() => {
        if (selectAll.value) return "mdi-checkbox-marked";
        if (selectSome.value) return "mdi-minus-box";
        return "mdi-checkbox-blank-outline";
      });

      const toggle = () => {
        if (selectAll.value) {
          basket.value = [];
        } else {
          basket.value = [];
          getters_grades.value.forEach((i) => basket.value.push(i.id));
        }
      };

      const removeProgramBtn = (id, program) => {
        actionResponse.value = false;
        overlay.value = true;
        removeProgram(id)
          .then(() => {
            overlay.value = false;
            getPaginateProgram().then(() => {
              programTable.isLoaded = false;
            });
          })
          .catch((e) => {
            overlay.value = false;
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            color.value = "error";
            actionResponse.value = true;
            if (e.response.status === 500) {
              msgBody.value =
                program + " cannot be deleted.It is used in other component(s)";
            } else if (e.response.status === 423) {
              msgBody.value = e.response.data.message;
              signOut().then(() => {
                vm.proxy.$router.replace({
                  name: "Login",
                });
              });
            } else {
              msgBody.value = "This action is unauthorized";
            }
          });
      };

      provide("color", color);

      return {
        ...toRefs(programTable),
        basketWithIndex,
        removeProgramBtn,
        getters_grades,
        openGradesProgram,
        icon,
        toggle,
        saveGradesChangesBtn,
      };
    },
  };
</script>
<style>
  .mycolor {
    color: #ffc601;
  }
</style>
